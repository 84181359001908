import * as React from "react";
import { Button, Card, Elevation, Tag } from "@blueprintjs/core";
import { joinGame, startGame } from "./redux/actions";
import { connect } from "react-redux";
import { RootState } from "./redux/reducers";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import GameBoard from "./GameBoard";
import GameDebugger from "./GameDebugger";
import { COLORS } from "./colors";
import ErrorPage from "./ErrorPage";

export interface GameLobbyRouteProps {
    match: {
        params: {
            gameId: string;
        };
    };
}

type GameLobbyProps = GameLobbyRouteProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class GameLobby extends React.PureComponent<GameLobbyProps> {
    componentDidMount() {
        this.props.joinGame(this.gameId());
    }

    public render() {
        if (this.props.isGameNotFound) {
            return this.renderGameNotFound();
        }
        if (!this.props.gameName || !this.hasAllPlayerDetails()) {
            return null;
        }
        if (this.props.hasGameStarted) {
            return (
                <div>
                    <GameBoard gameId={this.gameId()} />
                    <GameDebugger gameId={this.gameId()} />
                </div>
            );
        }

        return (
            <div className="app">
                <div className="container">
                    <Card interactive={false} elevation={Elevation.FOUR}>
                        <h1>Game Lobby</h1>
                        <h2>{this.props.gameName}</h2>
                        <h3>
                            Players ({this.props.players.length}/{this.props.numPlayers}):
                        </h3>
                        <div>
                            {this.props.players.map((player, i) => (
                                <span key={player}>
                                    <Tag
                                        key={player}
                                        icon="person"
                                        round={true}
                                        style={{ backgroundColor: this.playerColor(i) }}
                                    >
                                        {this.getPlayerName(player)}
                                    </Tag>{" "}
                                    &nbsp;
                                </span>
                            ))}
                        </div>
                        <br></br>
                        {this.renderCallToAction()}
                    </Card>
                </div>
            </div>
        );
    }

    private renderGameNotFound() {
        return (
            <ErrorPage heading="Game Not Found">
                Game <em>{this.gameId()}</em> could not be found. Farewell, General!
            </ErrorPage>
        );
    }

    private renderCallToAction() {
        if (this.isHost()) {
            return (
                <Button
                    rightIcon="globe"
                    intent="primary"
                    text="Start Game!"
                    disabled={!this.hasAllPlayers()}
                    onClick={() => this.props.startGame(this.props.playerId!, this.gameId())}
                />
            );
        } else if (this.hasAllPlayers()) {
            return (
                <span>
                    <em>Waiting for host to start the game...</em>
                </span>
            );
        } else {
            return "";
        }
    }

    private gameId() {
        return this.props.match.params.gameId;
    }

    private hasAllPlayerDetails() {
        return this.props.players.every((id) => this.props.playerDetails.players.has(id));
    }

    private getPlayerName(playerId: string) {
        return this.props.playerDetails.players.get(playerId)!.username;
    }

    private isHost() {
        return this.props.hostId === this.props.playerId;
    }

    private hasAllPlayers() {
        return this.props.players.length === this.props.numPlayers;
    }

    private playerColor(i: number) {
        return COLORS[i];
    }
}

const mapStateToProps = (state: RootState) => ({
    ...state.gameLobbyState,
    playerId: state.userAuth.playerId,
    playerDetails: state.playerDetailsState,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            joinGame,
            startGame,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GameLobby);
