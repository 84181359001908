import { ActionTypes } from "../actionTypes";

const initialState = {
    playerId: null,
    playerName: null,
    editMode: false,
};

export interface UserAuthState {
    playerId: string | null;
    playerName: string | null;
    editMode: boolean;
}

export interface UpdateUserAuthAction {
    type: ActionTypes.UPDATE_USER_ID;
    payload: string;
}

export interface UpdateUsernameAction {
    type: ActionTypes.UPDATE_USER_NAME;
    payload: string;
}

export interface SetEditModeAction {
    type: ActionTypes.SET_EDIT_MODE;
    payload: boolean;
}

export type UserAuthActionTypes = UpdateUserAuthAction | UpdateUsernameAction | SetEditModeAction;

export default function(state: UserAuthState = initialState, action: UserAuthActionTypes): UserAuthState {
    switch (action.type) {
        case ActionTypes.UPDATE_USER_ID: {
            return {
                ...state,
                playerId: action.payload,
            };
        }
        case ActionTypes.UPDATE_USER_NAME: {
            return {
                ...state,
                playerName: action.payload,
            };
        }
        case ActionTypes.SET_EDIT_MODE: {
            return {
                ...state,
                editMode: action.payload,
            };
        }
        default:
            return state;
    }
}
