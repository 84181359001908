import * as React from "react";
import { setDebuggerActive, submitDebuggerGameCommand } from "./redux/actions";
import { RootState, fullGameState } from "./redux/reducers";
import { connect } from "react-redux";
import { Dialog, Classes, InputGroup, Spinner, Icon } from "@blueprintjs/core";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import ReactJson from "react-json-view";
import { DebuggerCommandState } from "./redux/reducers/gameDebugger";
import { registerComboKeyCallback } from "./keyboard";

const icons = new Map<DebuggerCommandState, string>([
    [DebuggerCommandState.PENDING, "⏳"],
    [DebuggerCommandState.SUCCESS, "✅"],
    [DebuggerCommandState.ERROR, "❌"],
]);

export interface ParentProvidedProps {
    gameId: string;
}

type GameDebuggerProps = ParentProvidedProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

class GameDebugger extends React.PureComponent<GameDebuggerProps> {
    public componentDidMount() {
        registerComboKeyCallback("k", () => this.props.setDebuggerActive(true));
    }

    public render() {
        const lastCommandLoading =
            this.props.commandHistory.length > 0 && this.props.commandHistory[0].state === DebuggerCommandState.PENDING;
        const maybeSpinner = lastCommandLoading ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined;

        return (
            <Dialog
                className="debugger"
                title="Game Debugger"
                icon="wrench"
                isOpen={this.props.isDebuggerActive}
                onClose={() => this.props.setDebuggerActive(false)}
            >
                <div className={Classes.DIALOG_BODY}>
                    <InputGroup
                        large={true}
                        leftIcon="ninja"
                        inputRef={(ref: HTMLInputElement | null) => {
                            console.log("m8 wwtf!");
                            if (ref) {
                                ref.addEventListener("keydown", (event) => {
                                    if (event.key === "Enter") {
                                        console.log("m8!");
                                        if (this.submitGameCommand(ref.value)) {
                                            ref.value = "";
                                        }
                                    }
                                });
                            }
                        }}
                        placeholder="Enter a game command"
                        rightElement={maybeSpinner}
                        disabled={lastCommandLoading}
                    />

                    {this.renderCommandHistory()}
                    <ReactJson src={this.props.gameState}></ReactJson>
                </div>
            </Dialog>
        );
    }

    private renderCommandHistory() {
        const history = this.props.commandHistory
            .map((cmd) => {
                if (cmd.diagnostics === "") {
                    return `${icons.get(cmd.state)!} ${cmd.command}`;
                } else {
                    return `${icons.get(cmd.state)!} ${cmd.command}\n    ${cmd.diagnostics}`;
                }
            })
            .join("\n");
        return <pre className="command-history">{history}</pre>;
    }

    private submitGameCommand(text: string): boolean {
        try {
            const tokens = text.split(/ +/);
            console.log(tokens);
            const playerId = this.resolvePlayerId(tokens[0] || "");
            let command = tokens[1] || "";
            command = command.toUpperCase();

            if (!playerId) {
                return false;
            }

            console.log(command, playerId);
            switch (command) {
                case "BATCH_REINFORCE": {
                    let [, , to, reinforcementSizeStr] = tokens;
                    to = to.toUpperCase();
                    const reinforcementSize = parseInt(reinforcementSizeStr);
                    let reinforcements = { [to]: reinforcementSize };
                    this.props.submitDebuggerGameCommand(text, {
                        playerId,
                        gameId: this.props.gameId,
                        type: command,
                        reinforcements,
                    });
                    return true;
                }
                case "REINFORCE": {
                    let [, , to, reinforcementSizeStr] = tokens;
                    to = to.toUpperCase();
                    const reinforcementSize = parseInt(reinforcementSizeStr);
                    this.props.submitDebuggerGameCommand(text, {
                        playerId,
                        gameId: this.props.gameId,
                        type: command,
                        to,
                        reinforcementSize,
                    });
                    return true;
                }
                case "ATTACK": {
                    let [, , from, to, stopAttackingAtPartySizeStr] = tokens;
                    const stopAttackingAtPartySize = parseInt(stopAttackingAtPartySizeStr);
                    from = from.toUpperCase();
                    to = to.toUpperCase();
                    this.props.submitDebuggerGameCommand(text, {
                        playerId,
                        gameId: this.props.gameId,
                        type: command,
                        from,
                        to,
                        stopAttackingAtPartySize,
                    });
                    return true;
                }
                case "CONQUER": {
                    let [, , from, to, conqueringPartySizeStr] = tokens;
                    const conqueringPartySize = parseInt(conqueringPartySizeStr);
                    from = from.toUpperCase();
                    to = to.toUpperCase();
                    this.props.submitDebuggerGameCommand(text, {
                        playerId,
                        gameId: this.props.gameId,
                        type: command,
                        from,
                        to,
                        conqueringPartySize,
                    });
                    return true;
                }
                case "HAND_IN_CARDS": {
                    let [, , shouldHandInStr] = tokens;
                    const shouldHandIn = shouldHandInStr.toUpperCase() === "TRUE";
                    this.props.submitDebuggerGameCommand(text, {
                        playerId,
                        shouldHandIn,
                        gameId: this.props.gameId,
                        type: command,
                    });
                    return true;
                }

                case "END_ATTACK_PHASE": {
                    this.props.submitDebuggerGameCommand(text, {
                        playerId,
                        gameId: this.props.gameId,
                        type: command,
                    });
                    return true;
                }
                case "MOVE": {
                    let [, , from, to, movePartySizeStr] = tokens;
                    from = from.toUpperCase();
                    to = to.toUpperCase();
                    const movePartySize = parseInt(movePartySizeStr);
                    this.props.submitDebuggerGameCommand(text, {
                        playerId,
                        gameId: this.props.gameId,
                        type: command,
                        from,
                        to,
                        movePartySize,
                    });
                    return true;
                }
                case "SKIP_MOVE": {
                    this.props.submitDebuggerGameCommand(text, {
                        playerId,
                        gameId: this.props.gameId,
                        type: command,
                    });
                    return true;
                }
            }
        } catch (e) {
            // bad user input
        }

        return false;
    }

    private resolvePlayerId(name: string) {
        const playerDetails = [...this.props.players.values()].find((playerDetails) => {
            return playerDetails.playerId === name || playerDetails.username.toUpperCase() === name.toUpperCase();
        });
        return playerDetails?.playerId;
    }
}

const mapStateToProps = (state: RootState) => ({
    ...state.gameDebuggerState,
    playerId: state.userAuth.playerId,
    players: state.playerDetailsState.players,
    gameState: fullGameState(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            setDebuggerActive,
            submitDebuggerGameCommand,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GameDebugger);
