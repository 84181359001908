import { ActionTypes } from "../actionTypes";

export interface GameDescription {
    name: string;
    hasGameStarted: false;
}

export interface GameLink extends GameDescription {
    id: string;
}

export interface GameListState {
    games: GameLink[] | null;
}

const initialState = {
    games: null,
};

export interface UpdateGameListAction {
    type: string;
    payload: Map<string, GameDescription>;
}

export default function (state: GameListState = initialState, action: UpdateGameListAction): GameListState {
    switch (action.type) {
        case ActionTypes.UPDATE_GAME_LIST: {
            const games = Array.from(action.payload, ([key, description]) => ({
                id: key,
                name: description.name,
                hasGameStarted: description.hasGameStarted,
            }));
            return {
                ...state,
                games,
            };
        }
        default:
            return state;
    }
}
