import * as React from "react";

import { render } from "react-dom";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "./app.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import NavBar from "./NavBar";
import HostGame from "./HostGame";
import GameLobby from "./GameLobby";
import AuthLoader from "./AuthLoader";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Background from "./Background";

const App = () => (
    <Provider store={store}>
        <NavBar />
        <BrowserRouter>
            <Route
                render={({ location }) => (
                    <Background>
                        <AuthLoader>
                            <TransitionGroup>
                                <CSSTransition timeout={500} classNames="fade" key={location.key}>
                                    <Switch location={location}>
                                        <Route path="/game/:gameId" component={GameLobby}></Route>
                                        <Route path="/">
                                            <HostGame />
                                        </Route>
                                    </Switch>
                                </CSSTransition>
                            </TransitionGroup>
                        </AuthLoader>
                    </Background>
                )}
            />
        </BrowserRouter>
    </Provider>
);

render(<App />, document.getElementById("root"));
