import { combineReducers } from "redux";
import gameList from "./gameList";
import gameCreate from "./gameCreate";
import gameDescribe from "./gameLobby";
import userAuth from "./userAuth";
import playerDetails from "./playerDetails";
import gameDebugger from "./gameDebugger";
import gameBoard from "./gameBoard";
import { mapToObj } from "../../utils";

const rootReducer = combineReducers({
    userAuth: userAuth,
    gameListState: gameList,
    gameCreateState: gameCreate,
    gameLobbyState: gameDescribe,
    playerDetailsState: playerDetails,
    gameDebuggerState: gameDebugger,
    gameBoardState: gameBoard,
});
export type RootState = ReturnType<typeof rootReducer>;

export function fullGameState(state: RootState) {
    return {
        ...state.gameBoardState.gameStateBlob,
        playersById: mapToObj(state.playerDetailsState.players),
        localPlayerId: state.userAuth.playerId,
    };
}

export default rootReducer;
