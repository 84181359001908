import { ActionTypes } from "../actionTypes";

const initialState = {
    players: new Map(),
};

export interface PlayerDetailsState {
    players: Map<string, PlayerDetails>;
}

export interface PlayerDetails {
    playerId: string;
    username: string;
}

export interface UpdatePlayerDetailsAction {
    type: ActionTypes.UPDATE_PLAYER_DETAILS;
    payload: Map<string, PlayerDetails>;
}

export default function(
    state: PlayerDetailsState = initialState,
    action: UpdatePlayerDetailsAction
): PlayerDetailsState {
    switch (action.type) {
        case ActionTypes.UPDATE_PLAYER_DETAILS: {
            const players = new Map([...state.players, ...action.payload]);
            return {
                players,
            };
        }
        default:
            return state;
    }
}
