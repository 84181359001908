import * as React from "react";
import { authenticate } from "./redux/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { RootState } from "./redux/reducers";

type AuthLoaderProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class AuthLoader extends React.PureComponent<AuthLoaderProps> {
    componentDidMount() {
        this.props.authenticate();
    }

    public render() {
        return this.props.playerId === null ? "" : this.props.children;
    }
}

const mapStateToProps = (state: RootState) => ({
    ...state.userAuth,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            authenticate,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AuthLoader);
