import * as firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCzMpOGu0ZfBXZLyw2SbhRcj4mgyePfU04",
    authDomain: "conquest-9be26.firebaseapp.com",
    databaseURL: "https://conquest-9be26.firebaseio.com",
    projectId: "conquest-9be26",
    storageBucket: "conquest-9be26.appspot.com",
    messagingSenderId: "171057966803",
    appId: "1:171057966803:web:cf212a556badc06831ae26",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const database = firebase.database();
export const auth = firebase.auth();
