export type Color = string;

export const AMARANTH_RED = "#D62828";
export const TANGERINE = "#FCA311";
export const CARRIBEAN_GREEN = "#06D6A0";
export const CORNFLOWER_BLUE = "#118AB2";
export const CRAYON_MAGENTA = "#EF5DA4";
export const STEEL_BLUE = "#B8BEDD";

export const COLORS = [CORNFLOWER_BLUE, TANGERINE, CARRIBEAN_GREEN, CRAYON_MAGENTA, STEEL_BLUE, AMARANTH_RED];
