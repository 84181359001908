import * as React from "react";
import { Button, Navbar, Alignment, EditableText } from "@blueprintjs/core";
import { setEditMode, setUsername } from "./redux/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { RootState } from "./redux/reducers";

type NavBarProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class NavBar extends React.PureComponent<NavBarProps> {
    public render() {
        const playerName = this.props.playerName || "(unauthed)";
        return (
            <div>
                <Navbar>
                    <Navbar.Group align={Alignment.RIGHT}>
                        <a href="/">
                            <Button className="bp3-minimal" icon="globe" text="Conquest" />
                        </a>
                        <Navbar.Divider />
                        {this.props.editMode ? (
                            <EditableText
                                defaultValue={playerName}
                                confirmOnEnterKey={true}
                                onCancel={() => this.props.setEditMode(false)}
                                onConfirm={this.props.setUsername}
                            ></EditableText>
                        ) : (
                            <Button
                                className="bp3-minimal"
                                icon="user"
                                text={playerName}
                                onClick={() => this.props.setEditMode(true)}
                            />
                        )}
                    </Navbar.Group>
                </Navbar>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    ...state.userAuth,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            setEditMode,
            setUsername,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
