export enum ActionTypes {
    // nav bar/ auth
    UPDATE_USER_ID = "UPDATE_USER_ID",
    UPDATE_USER_NAME = "UPDATE_USER_NAME",
    SET_EDIT_MODE = "SET_EDIT_MODE",

    // home page
    UPDATE_GAME_LIST = "UPDATE_GAME_LIST",

    // game lobby
    SET_GAME_NOT_FOUND = "SET_GAME_NOT_FOUND",
    UPDATE_GAME_DESCRIPTION = "UPDATE_GAME_DESCRIPTION",
    SET_GAME_STARTED = "SET_GAME_STARTED",

    // debugger
    SET_DEBUGGER_ACTIVE = "SET_DEBUGGER_ACTIVE",
    PUSH_COMMAND = "PUSH_COMMAND",
    UPDATE_LAST_COMMAND_STATE = "UPDATE_LAST_COMMAND_STATE",

    // board
    SET_UNITY_CALLBACK = "SET_UNITY_CALLBACK",
    SET_GAME_STATE_BLOB = "SET_GAME_STATE_BLOB",

    // players
    UPDATE_PLAYER_DETAILS = "UPDATE_PLAYER_DETAILS",

    // game creation flow
    SET_GAME_NAME = "SET_GAME_NAME",
    SET_NUM_PLAYERS = "SET_NUM_PLAYERS",
    SET_NUM_BOT_PLAYERS = "SET_NUM_BOT_PLAYERS",
    CREATE_GAME = "CREATE_GAME",
}
