import { ActionTypes } from "../actionTypes";

const initialState = {
    playerName: "",
    gameName: "",
    numPlayers: 4,
    numBotPlayers: 0,
    createdGameId: null,
};

export interface CreateGameState {
    playerName: string;
    gameName: string;
    numPlayers: number;
    numBotPlayers: number;
    createdGameId: string | null;
}

export interface CreateGameAction {
    type: ActionTypes.CREATE_GAME;
    payload: string;
}

export interface SetGameNameAction {
    type: ActionTypes.SET_GAME_NAME;
    payload: string;
}

export interface SetNumPlayersAction {
    type: ActionTypes.SET_NUM_PLAYERS;
    payload: number;
}

export interface SetNumBotPlayersAction {
    type: ActionTypes.SET_NUM_BOT_PLAYERS;
    payload: number;
}

export type CreateGameActionTypes = CreateGameAction | SetGameNameAction | SetNumPlayersAction | SetNumBotPlayersAction;

export default function (state: CreateGameState = initialState, action: CreateGameActionTypes): CreateGameState {
    switch (action.type) {
        case ActionTypes.CREATE_GAME: {
            return {
                ...state,
                createdGameId: action.payload,
            };
        }
        case ActionTypes.SET_GAME_NAME: {
            return {
                ...state,
                gameName: action.payload,
            };
        }
        case ActionTypes.SET_NUM_PLAYERS: {
            return {
                ...state,
                numPlayers: action.payload,
            };
        }
        case ActionTypes.SET_NUM_BOT_PLAYERS: {
            return {
                ...state,
                numBotPlayers: action.payload,
            };
        }
        default:
            return state;
    }
}
