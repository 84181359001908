document.addEventListener("keydown", (e) => {}, false);

export function registerComboKeyCallback(key: string, callback: () => void) {
    let isShiftDown = false;
    let isMetaDown = false;
    let isKeyDown = false;
    document.addEventListener(
        "keydown",
        (e) => {
            isShiftDown = isShiftDown || e.key === "Shift";
            isMetaDown = isMetaDown || e.key === "Meta";
            isKeyDown = isKeyDown || e.key.toLowerCase() === key.toLowerCase();
            if (isKeyDown && (isShiftDown || isMetaDown)) {
                callback();
            }
        },
        false
    );
    document.addEventListener(
        "keyup",
        (e) => {
            isShiftDown = isShiftDown && !(e.key === "Shift");
            isMetaDown = isMetaDown && !(e.key === "Meta");
            isKeyDown = isKeyDown && !(e.key.toLowerCase() === key.toLowerCase());
        },
        false
    );
}
