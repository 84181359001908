import * as React from "react";
import { Card, Elevation } from "@blueprintjs/core";

interface ErrorPageProps {
    heading: string;
}

export default class ErrorPage extends React.PureComponent<ErrorPageProps> {
    public render() {
        return (
            <div className="app">
                <div className="container">
                    <Card interactive={false} elevation={Elevation.THREE}>
                        <h1>{this.props.heading}</h1>
                        <div className="error-container">
                            <div>{this.props.children}</div>
                            <img alt="General Wolfe's Death" className="error-image" src="/wolfe.jpg" />
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}
