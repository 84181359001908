import { ActionTypes } from "../actionTypes";

export interface GameBoardState {
    unityStateCallback: (jsonState: string) => void | null;
    gameStateBlob: any;
}

const initialState = {
    unityStateCallback: () => console.log("unity not yet initialised"),
    gameStateBlob: {},
};

export interface SetUnityCallbackAction {
    type: ActionTypes.SET_UNITY_CALLBACK;
    payload: (jsonState: string) => void;
}

export interface SetGameStateBlobAction {
    type: ActionTypes.SET_GAME_STATE_BLOB;
    payload: any;
}

export type GameBoardActionTypes = SetUnityCallbackAction | SetGameStateBlobAction;

export default function (state: GameBoardState = initialState, action: GameBoardActionTypes): GameBoardState {
    switch (action.type) {
        case ActionTypes.SET_UNITY_CALLBACK: {
            return {
                ...state,
                unityStateCallback: action.payload,
            };
        }
        case ActionTypes.SET_GAME_STATE_BLOB: {
            return {
                ...state,
                gameStateBlob: action.payload,
            };
        }
        default:
            return state;
    }
}
