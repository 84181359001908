import { ActionTypes } from "../actionTypes";

export enum DebuggerCommandState {
    PENDING,
    SUCCESS,
    ERROR,
}

export interface DebuggerCommand {
    state: DebuggerCommandState;
    command: string;
    diagnostics: string;
}

export interface GameDebuggerState {
    isDebuggerActive: boolean;
    isCommandPending: boolean;
    commandHistory: DebuggerCommand[];
}

const initialState = {
    isDebuggerActive: false,
    isCommandPending: false,
    commandHistory: [],
};

export interface SetDebuggerActiveAction {
    type: ActionTypes.SET_DEBUGGER_ACTIVE;
    payload: boolean;
}

export interface PushCommandAction {
    type: ActionTypes.PUSH_COMMAND;
    payload: DebuggerCommand;
}

export interface UpdateLastCommandStateAction {
    type: ActionTypes.UPDATE_LAST_COMMAND_STATE;
    payload: {
        state: DebuggerCommandState;
        diagnostics: string;
    };
}

export type GameDebuggerActionTypes = SetDebuggerActiveAction | PushCommandAction | UpdateLastCommandStateAction;

export default function (state: GameDebuggerState = initialState, action: GameDebuggerActionTypes): GameDebuggerState {
    switch (action.type) {
        case ActionTypes.SET_DEBUGGER_ACTIVE: {
            return {
                ...state,
                isDebuggerActive: action.payload,
            };
        }
        case ActionTypes.PUSH_COMMAND: {
            return {
                ...state,
                commandHistory: [action.payload, ...state.commandHistory],
            };
        }
        case ActionTypes.UPDATE_LAST_COMMAND_STATE: {
            if (state.commandHistory.length === 0 || state.commandHistory[0].state !== DebuggerCommandState.PENDING) {
                return state;
            } else {
                const newCommandState = {
                    ...state.commandHistory[0],
                    ...action.payload,
                };
                return {
                    ...state,
                    commandHistory: [newCommandState, ...state.commandHistory.slice(1)],
                };
            }
        }
        default:
            return state;
    }
}
