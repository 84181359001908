import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { RootState } from "./redux/reducers";

type BackgroundProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Background extends React.PureComponent<BackgroundProps> {
    public render() {
        const className = this.props.hasGameStarted ? "" : "background";
        return <div className={className}>{this.props.children}</div>;
    }
}

const mapStateToProps = (state: RootState) => ({
    hasGameStarted: state.gameLobbyState.hasGameStarted,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Background);
