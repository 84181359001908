import * as React from "react";
import { Button, Card, Elevation, FormGroup, InputGroup, NumericInput, Spinner } from "@blueprintjs/core";
import { listGames, clearCreatedGame, createGame, setGameName, setNumPlayers, setNumBotPlayers } from "./redux/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { RootState } from "./redux/reducers";
import { Link, Redirect } from "react-router-dom";

type HostGameProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class HostGame extends React.PureComponent<HostGameProps> {
    componentDidMount() {
        this.props.listGames();
    }

    componentWillUnmount() {
        this.props.clearCreatedGame();
    }

    public render() {
        return this.props.createdGameId != null ? (
            this.redirect()
        ) : (
            <div className="app">
                <div className="container">
                    <Card interactive={false} elevation={Elevation.THREE}>
                        <h1>Open Games</h1>
                        {this.renderOpenGames()}
                        <h1>Host New Game</h1>
                        <br></br>
                        <div className="host-game-dialog">
                            <FormGroup inline={false} label="Game Name" labelFor="text-input">
                                <InputGroup
                                    fill={true}
                                    id="text-input"
                                    placeholder="Epic Death Match"
                                    value={this.props.gameName}
                                    onChange={this.onGameNameChange}
                                />
                            </FormGroup>
                            <FormGroup inline={false} label="Human Players" labelFor="text-input">
                                <NumericInput
                                    fill={true}
                                    min={1}
                                    max={6 - this.props.numBotPlayers}
                                    id="text-input"
                                    value={this.props.numPlayers}
                                    onValueChange={this.props.setNumPlayers}
                                />
                            </FormGroup>
                            <FormGroup inline={false} label="Bot Players" labelFor="text-input">
                                <NumericInput
                                    fill={true}
                                    min={0}
                                    max={6 - this.props.numPlayers}
                                    id="text-input"
                                    value={this.props.numBotPlayers}
                                    onValueChange={this.props.setNumBotPlayers}
                                />
                            </FormGroup>
                            <Button
                                rightIcon="arrow-right"
                                intent="primary"
                                text="Create Game"
                                onClick={() => this.props.createGame()}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

    private redirect() {
        return <Redirect push={true} to={"/game/" + this.props.createdGameId} />;
    }

    private renderOpenGames() {
        if (this.props.games === null) {
            return <Spinner size={Spinner.SIZE_SMALL}></Spinner>;
        } else {
            const games = this.props.games.filter((game) => !game.hasGameStarted);
            if (games.length === 0) {
                return <span>(no open games)</span>;
            } else {
                return games.map((game, i) => (
                    <span key={i}>
                        {i > 0 && " | "}
                        <Link key={i} to={"/game/" + game.id}>
                            {game.name}
                        </Link>
                    </span>
                ));
            }
        }
    }

    private onGameNameChange = handleStringChange(this.props.setGameName);
}

function handleStringChange(handler: (value: string) => void) {
    return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).value);
}

const mapStateToProps = (state: RootState) => ({
    games: state.gameListState.games,
    ...state.gameCreateState,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            listGames,
            createGame,
            clearCreatedGame,
            setGameName,
            setNumPlayers,
            setNumBotPlayers,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(HostGame);
