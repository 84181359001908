import store from "./store";
import { ActionTypes } from "./actionTypes";
import { auth, database } from "../GameDb";
import { Dispatch } from "redux";
import { UpdateGameListAction } from "./reducers/gameList";
import { UpdateGameDescriptionAction, SetGameNotFoundAction } from "./reducers/gameLobby";
import { CreateGameAction } from "./reducers/gameCreate";
import { UpdatePlayerDetailsAction } from "./reducers/playerDetails";
import { SetEditModeAction, UpdateUserAuthAction, UpdateUsernameAction } from "./reducers/userAuth";
import { DebuggerCommandState, PushCommandAction, UpdateLastCommandStateAction } from "./reducers/gameDebugger";
import { COLORS } from "../colors";
import { fullGameState } from "./reducers";
import { SetGameStateBlobAction } from "./reducers/gameBoard";

export const authenticate = () => async (dispatch: Dispatch<UpdateUserAuthAction | UpdateUsernameAction>) => {
    try {
        await auth.signInAnonymously();
    } catch (e) {
        // TODO handle auth error
        console.log(e);
    }
    auth.onAuthStateChanged(function (user) {
        console.log("AUTHED!", user);
        if (user) {
            dispatch({
                type: ActionTypes.UPDATE_USER_ID,
                payload: user.uid,
            });
            database.ref("users/" + user.uid).on("value", (snapshot) => {
                if (snapshot.val() && snapshot.val()["username"]) {
                    dispatch({
                        type: ActionTypes.UPDATE_USER_NAME,
                        payload: snapshot.val()["username"],
                    });
                } else {
                    writeUsernameToDb("Guest_" + randomId());
                }
            });
        }
    });
};

export const setUsername = (newUsername: string) => async (dispatch: Dispatch<SetEditModeAction>) => {
    await writeUsernameToDb(newUsername);
    dispatch(setEditMode(false));
};

export const setEditMode = (editMode: boolean): SetEditModeAction => ({
    type: ActionTypes.SET_EDIT_MODE,
    payload: editMode,
});

export const listGames = () => async (dispatch: Dispatch<UpdateGameListAction>) => {
    database.ref("games/").on("value", (snapshot) => {
        dispatch({
            type: ActionTypes.UPDATE_GAME_LIST,
            payload: new Map(Object.entries(snapshot.val() || {})),
        });
    });
};

export const joinGame = (id: string) => async (
    dispatch: Dispatch<
        | UpdateGameDescriptionAction
        | UpdatePlayerDetailsAction
        | UpdateLastCommandStateAction
        | SetGameStateBlobAction
        | SetGameNotFoundAction
    >
) => {
    database.ref("games/" + id).on("value", (snapshot) => {
        const gameState = snapshot.val();
        if (gameState === null) {
            dispatch({
                type: ActionTypes.SET_GAME_NOT_FOUND,
                payload: null,
            });
            return;
        }
        dispatch({
            type: ActionTypes.UPDATE_GAME_DESCRIPTION,
            payload: {
                ...gameState,
            },
        });
        dispatch({
            type: ActionTypes.SET_GAME_STATE_BLOB,
            payload: {
                ...gameState,
            },
        });

        const committedActions = gameState.committedActions;
        if (committedActions && committedActions.length > 0) {
            const lastAction = committedActions[committedActions.length - 1];
            dispatch({
                type: ActionTypes.UPDATE_LAST_COMMAND_STATE,
                payload: {
                    state:
                        lastAction.status === "COMMITTED" ? DebuggerCommandState.SUCCESS : DebuggerCommandState.ERROR,
                    diagnostics: lastAction.diagnostics,
                },
            });
        }
        const numPlayers = snapshot.val().numPlayers;
        const players: string[] = snapshot.val().players || [];
        const playerId = store.getState().userAuth.playerId;

        players.map((player) => registerPlayerListener(player, dispatch));

        if (playerId !== null) {
            if (players.length !== numPlayers && !players.includes(playerId)) {
                players.push(playerId);
                database.ref("games/" + id).set({
                    ...snapshot.val(),
                    players,
                });
            }
        }

        store.getState().gameBoardState.unityStateCallback({
            ...fullGameState(store.getState()),
            ...gameState,
        });
    });
};

export const startGame = (playerId: string, gameId: string) => async (dispatch: any) => {
    database.ref("messages/").push({
        playerId,
        gameId,
        type: "START_GAME",
    });
};

export const clearCreatedGame = () => ({
    type: ActionTypes.CREATE_GAME,
    payload: null,
});

export const setUnityCallback = (callback: (jsonGameState: string) => void) => ({
    type: ActionTypes.SET_UNITY_CALLBACK,
    payload: callback,
});

export const createGame = () => async (dispatch: Dispatch<CreateGameAction>) => {
    const createdGameId = randomId();
    const { gameName, numPlayers, numBotPlayers } = store.getState().gameCreateState;
    await database.ref("games/" + createdGameId).set({
        name: gameName,
        hostId: store.getState().userAuth.playerId,
        numPlayers,
        numBotPlayers,
        players: [store.getState().userAuth.playerId],
        colors: COLORS,
        hasGameStarted: false,
    });
    dispatch({
        type: ActionTypes.CREATE_GAME,
        payload: createdGameId,
    });
};

export const setGameName = (name: string) => ({
    type: ActionTypes.SET_GAME_NAME,
    payload: name,
});

export const setNumPlayers = (numPlayers: number) => ({
    type: ActionTypes.SET_NUM_PLAYERS,
    payload: numPlayers,
});

export const setNumBotPlayers = (numBotPlayers: number) => ({
    type: ActionTypes.SET_NUM_BOT_PLAYERS,
    payload: numBotPlayers,
});

async function writeUsernameToDb(username: string) {
    await database.ref("users/" + store.getState().userAuth.playerId).set({
        username,
    });
}

export const setDebuggerActive = (isActive: boolean) => ({
    type: ActionTypes.SET_DEBUGGER_ACTIVE,
    payload: isActive,
});

export const submitGameCommand = (command: any) => async (dispatch: any) => {
    database.ref("messages/").push(command);
};

export const submitDebuggerGameCommand = (commandString: string, command: any) => async (
    dispatch: Dispatch<PushCommandAction>
) => {
    console.log("GNAW!", command);
    database.ref("messages/").push(command);
    dispatch({
        type: ActionTypes.PUSH_COMMAND,
        payload: {
            command: commandString,
            state: DebuggerCommandState.PENDING,
            diagnostics: "",
        },
    });
};

const playerListeners: string[] = [];
function registerPlayerListener(playerId: string, dispatch: Dispatch<UpdatePlayerDetailsAction>) {
    if (!playerListeners.includes(playerId)) {
        playerListeners.push(playerId);
        database.ref("users/" + playerId).on("value", (snapshot) => {
            dispatch({
                type: ActionTypes.UPDATE_PLAYER_DETAILS,
                payload: new Map([
                    [
                        playerId,
                        {
                            playerId,
                            ...snapshot.val(),
                        },
                    ],
                ]),
            });
        });
    }
}

function randomId() {
    return Math.random().toString(36).substring(2, 15);
}
