import { ActionTypes } from "../actionTypes";

export interface GameLobbyState {
    gameName: string;
    hostId: string;
    players: string[];
    numPlayers: number;
    hasGameStarted: boolean;
    isGameNotFound: boolean;
}

export interface GameDescription {
    name: string;
    hostId: string;
    players: string[];
    numPlayers: number;
    hasGameStarted: boolean;
}

const initialState = {
    gameName: "",
    hostId: "",
    players: [],
    numPlayers: 0,
    hasGameStarted: false,
    isGameNotFound: false,
};

export interface UpdateGameDescriptionAction {
    type: ActionTypes.UPDATE_GAME_DESCRIPTION;
    payload: GameDescription;
}

export interface SetGameNotFoundAction {
    type: ActionTypes.SET_GAME_NOT_FOUND;
    payload: null;
}

export type GameLobbyActionTypes = UpdateGameDescriptionAction | SetGameNotFoundAction;

export default function (state: GameLobbyState = initialState, action: GameLobbyActionTypes): GameLobbyState {
    switch (action.type) {
        case ActionTypes.UPDATE_GAME_DESCRIPTION: {
            return {
                ...state,
                gameName: action.payload.name,
                hostId: action.payload.hostId,
                players: action.payload.players,
                numPlayers: action.payload.numPlayers,
                hasGameStarted: action.payload.hasGameStarted,
                ...action.payload,
            };
        }
        case ActionTypes.SET_GAME_NOT_FOUND: {
            return {
                ...state,
                isGameNotFound: true,
            };
        }
        default:
            return state;
    }
}
