import * as React from "react";
import Unity, { UnityContent } from "react-unity-webgl";
import { RootState, fullGameState } from "./redux/reducers";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { submitGameCommand, setUnityCallback } from "./redux/actions";
import { connect } from "react-redux";

export interface ParentProvidedProps {
    gameId: string;
}

type GameBoardProps = ParentProvidedProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

// super janky hack to prevent unity from taking over keyboard events
const realWindowAddEventListener = window.addEventListener;
function fakeAddEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
): void {
    if (type === "keyup" || type === "keypress" || type === "keydown") {
        console.log("blocking calls to register keyboard event listeners");
    } else {
        realWindowAddEventListener(type, listener, options);
    }
}
window.addEventListener = fakeAddEventListener;

const unityContent = new UnityContent("/game-build/Build/game-build.json", "/game-build/Build/UnityLoader.js");

class GameBoard extends React.PureComponent<GameBoardProps> {
    public componentDidMount() {
        unityContent.on("GameStart", () => {
            console.log("GAME STARTED!!!");
            window.setTimeout(() => {
                unityContent.send("TestConnection", "PushGameUpdate", JSON.stringify(this.props.gameState));
                this.props.setUnityCallback((state) => {
                    console.log("We have an update dale", state);
                    unityContent.send("TestConnection", "PushGameUpdate", JSON.stringify(state));
                });
                unityContent.on("SubmitPlayerAction", (msg: string) => {
                    console.log(msg);
                    this.props.submitGameCommand({
                        ...JSON.parse(msg),
                        gameId: this.props.gameId,
                    });
                });
            }, 1000);
        });
    }

    public render() {
        return <div>{<Unity unityContent={unityContent} />}</div>;
    }
}

const mapStateToProps = (state: RootState) => ({
    gameState: fullGameState(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            submitGameCommand,
            setUnityCallback: setUnityCallback,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GameBoard);
